import type { FC } from "react";
import React from "react";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import PharmacyForm from "../../PharmacyForm/PharmacyForm";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import translate from "src/lib/translate";
import { useSearchParams } from "react-router-dom";

const SelectPharmacy: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [query] = useSearchParams();

  const showTaskSkip = query.get("showTaskSkip") === "true";

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const handleSaved = () => {
    tracker.track("Pharmacy Information Added/Updated" as TrackEvent);
    AppQueryPopupsController.closePopup();
  };

  return (
    <InDialog
      popup
      title={translate("yourPharmacy")}
      onClose={onClose}
      returnUrl={returnUrl}
    >
      <PharmacyForm showTaskSkip={showTaskSkip} onPharmacySaved={handleSaved} />
    </InDialog>
  );
};

export default SelectPharmacy;
